import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-instagram-color")
export class VillaLogoInstagramColor extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        width="32"
        height="32"
      >
        <path
          fill="url(#a)"
          d="M23.787 29.985 8.24 30c-3.42.003-6.221-2.792-6.225-6.213L2 8.24c-.003-3.42 2.792-6.221 6.213-6.225L23.76 2c3.42-.003 6.221 2.792 6.225 6.213L30 23.76c.004 3.421-2.792 6.222-6.213 6.225Z"
        />
        <path
          fill="url(#b)"
          d="M23.787 29.985 8.24 30c-3.42.003-6.221-2.792-6.225-6.213L2 8.24c-.003-3.42 2.792-6.221 6.213-6.225L23.76 2c3.42-.003 6.221 2.792 6.225 6.213L30 23.76c.004 3.421-2.792 6.222-6.213 6.225Z"
        />
        <path
          fill="#fff"
          d="M16 21.442c-3 0-5.441-2.441-5.441-5.442 0-3 2.441-5.441 5.441-5.441s5.442 2.44 5.442 5.441S19 21.442 16 21.442Zm0-9.328A3.891 3.891 0 0 0 12.113 16 3.891 3.891 0 0 0 16 19.887 3.891 3.891 0 0 0 19.887 16 3.891 3.891 0 0 0 16 12.114ZM21.83 11.336a1.166 1.166 0 1 0 0-2.332 1.166 1.166 0 0 0 0 2.332Z"
        />
        <path
          fill="#fff"
          d="M20.665 26.106h-9.329c-3 0-5.441-2.44-5.441-5.442v-9.328c0-3 2.441-5.441 5.441-5.441h9.329c3 0 5.441 2.44 5.441 5.441v9.329c0 3-2.442 5.441-5.442 5.441ZM11.335 7.45a3.891 3.891 0 0 0-3.887 3.887v9.329a3.891 3.891 0 0 0 3.887 3.886h9.329a3.891 3.891 0 0 0 3.886-3.886v-9.329a3.891 3.891 0 0 0-3.886-3.887h-9.329Z"
        />
        <defs>
          <radialGradient
            id="a"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="translate(12.409 30.02) scale(34.903)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FD5" />
            <stop offset=".328" stop-color="#FF543F" />
            <stop offset=".348" stop-color="#FC5245" />
            <stop offset=".504" stop-color="#E64771" />
            <stop offset=".643" stop-color="#D53E91" />
            <stop offset=".761" stop-color="#CC39A4" />
            <stop offset=".841" stop-color="#C837AB" />
          </radialGradient>
          <radialGradient
            id="b"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(23.1757 0 0 15.4419 6.506 1.65)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4168C9" />
            <stop offset=".999" stop-color="#4168C9" stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>
    `;
  }

  static styles = logoStyles;
}
