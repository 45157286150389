import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-facebook-mono")
export class VillaLogoFacebookMono extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="none"
        width="32"
        height="32"
      >
        <path
          fill="currentColor"
          d="M17.675 25.848v-9.046h3.194l.39-3.65h-3.584v-2.28c0-1.064.311-1.748 1.791-1.748h1.948V6.007h-2.882a4.5 4.5 0 0 0-3.186 1.077 4.288 4.288 0 0 0-1.488 2.952v3.269h-3.272v3.497h3.194V26l3.895-.152Z"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
