import "../villa-icons/villa-icon-spinner.ts";

import { css, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";

import { VillaButton } from "./villa-button";

@customElement("villa-button-submit")
export class VillaButtonSubmit extends VillaButton {
  static formAssociated = true;

  @state() isLoading: boolean | undefined;

  @property({ type: Boolean, reflect: true }) loading: boolean | undefined;

  #internals;
  constructor() {
    super();

    /*
     * We have to do this additional "typeof window" check to make sure that we are in the browser.
     * Otherwise the "... in window" check breaks the server-side rendering. This means that the
     * attachInternals function is not executed on the server. Since the attachInternals function
     * seems to only be relevant when hydrating the component on the client
     * it feels safe to not execute it on the server.
     * */
    if (typeof window !== "undefined" && "ElementInternals" in window) {
      this.#internals = this.attachInternals();
    }
  }

  updated(changedProperties: any) {
    super.updated(changedProperties);

    if (!changedProperties.has("loading") || !this.loading) return false;
    setTimeout(() => (this.isLoading = true), 250);
  }

  #handleButtonClick = (e: Event) => {
    e.preventDefault();
    const form =
      "ElementInternals" in window
        ? this.#internals?.form
        : this.closest("form");

    if (form) {
      if (form.requestSubmit) {
        form.requestSubmit();
      } else if (form.reportValidity()) {
        form.submit();
      }
    }
  };

  render() {
    return html`
      <button
        class="VillaButton"
        ?disabled="${this.disabled || this.isLoading}"
        aria-disabled="${ifDefined(this.disabled)}"
        aria-label="${this.ariaLabel || "Submit button"}"
        @click="${this.#handleButtonClick}"
        type="submit"
      >
        <span class="VillaButton-label">
          ${this.loading
            ? html`
                <villa-icon-spinner
                  class="VillaButton-loader"
                ></villa-icon-spinner>
              `
            : nothing}
          <slot></slot>
        </span>
      </button>
    `;
  }

  static styles = [
    ...VillaButton.styles,
    css`
      .VillaButton[type="submit"] {
        width: var(--villa-button-submit-width, auto);
      }

      .VillaButton[type="submit"] .VillaButton-label {
        position: relative;
        padding: 0 1rem;
      }

      .VillaButton-loader {
        --villa-icon-width: 1.25rem;

        position: absolute;
        left: -0.75rem;
      }
    `,
  ];
}
