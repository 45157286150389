import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-linkedin-mono")
export class VillaLogoLinkedinMono extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="none"
        width="32"
        height="32"
      >
        <path
          fill="currentColor"
          d="M6.995 25.053h3.677V13.06H6.995v11.994ZM11.272 8.955a2.253 2.253 0 0 1-.789 1.486 2.224 2.224 0 0 1-1.593.518 2.224 2.224 0 0 1-1.598-.514 2.253 2.253 0 0 1-.792-1.49 2.269 2.269 0 0 1 .763-1.548 2.239 2.239 0 0 1 1.627-.551 2.233 2.233 0 0 1 1.622.554c.446.394.72.95.76 1.545ZM21.302 12.551c-2.608 0-3.772 1.304-4.068 1.803v-1.4h-3.902v11.994h3.876V17.95s.296-2.09 2.182-2.09 2.181 1.898 2.181 2.493v6.798H25.5v-7.2c-.096-2.293-.99-5.399-4.172-5.399h-.026Z"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
