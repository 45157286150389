import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-x")
export class VillaLogoX extends LitElement {
  @property() variant: "mono" | undefined;

  get #color() {
    return this.variant === "mono" ? "currentColor" : "#03A9F4";
  }
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        width="32"
        height="32"
      >
        <path
          fill="${this.#color}"
          d="M23.356 3.5h3.963L18.66 13.667 28.846 27.5H20.87l-6.246-8.39-7.148 8.39H3.51l9.26-10.873L3 3.5h8.178l5.646 7.669L23.356 3.5Zm-1.392 21.563h2.197L9.985 5.81H7.628l14.336 19.254Z"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
