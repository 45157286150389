import { css } from "lit";

import { iconStyles } from "../villa-icons/villa-icon-styles";

export const logoStyles = [
  iconStyles,
  css`
    :host([size="sm"]) {
      --villa-icon-width: 1.5rem; /* 24px */
    }

    :host([size="md"]) {
      --villa-icon-width: 2rem; /* 32px */
    }

    :host([size="lg"]) {
      --villa-icon-width: 2.5rem; /* 40px */
    }
  `,
];
