import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-instagram-mono")
export class VillaLogoInstagramMono extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="none"
        width="32"
        height="32"
      >
        <path
          fill="currentColor"
          d="M13.038 16c0-.603.167-1.192.481-1.694.314-.501.76-.892 1.283-1.123a2.692 2.692 0 0 1 1.65-.173 2.807 2.807 0 0 1 1.464.834c.4.427.672.97.782 1.561a3.24 3.24 0 0 1-.163 1.762 3.015 3.015 0 0 1-1.052 1.368 2.732 2.732 0 0 1-1.588.514 2.676 2.676 0 0 1-1.098-.224 2.824 2.824 0 0 1-.93-.66 3.041 3.041 0 0 1-.62-.994 3.197 3.197 0 0 1-.21-1.171Zm-1.464 0c0 .912.254 1.803.728 2.561a4.396 4.396 0 0 0 1.94 1.698 4.07 4.07 0 0 0 2.496.263 4.245 4.245 0 0 0 2.213-1.262 4.714 4.714 0 0 0 1.183-2.36 4.897 4.897 0 0 0-.246-2.664 4.559 4.559 0 0 0-1.592-2.07 4.131 4.131 0 0 0-2.4-.776c-1.145.005-2.24.493-3.05 1.356A4.793 4.793 0 0 0 11.574 16Zm7.876-4.833a1.06 1.06 0 0 0 .279.742.925.925 0 0 0 .697.299c.265-.003.519-.112.712-.304.193-.193.312-.456.333-.737a1.08 1.08 0 0 0-.068-.402 1.026 1.026 0 0 0-.211-.341.95.95 0 0 0-.32-.225.902.902 0 0 0-.377-.073.912.912 0 0 0-.396.054.96.96 0 0 0-.341.22 1.035 1.035 0 0 0-.23.349c-.053.132-.08.274-.078.418Zm-7.04 12.269-1.603-.297-.975-.67a3.146 3.146 0 0 1-.628-1.04 4.626 4.626 0 0 1-.278-1.711v-7.436a4.626 4.626 0 0 1 .278-1.71c.142-.389.355-.743.628-1.041l.975-.67 1.604-.223h6.97l1.602.298.976.669c.272.299.486.653.628 1.041.199.544.294 1.126.278 1.71v7.436a4.625 4.625 0 0 1-.278 1.71 3.149 3.149 0 0 1-.628 1.042l-.976.669-1.603.223h-6.97Zm0-16.36a5.19 5.19 0 0 0-2.09.373 4.163 4.163 0 0 0-1.483 1.02 4.506 4.506 0 0 0-.957 1.582 6.222 6.222 0 0 0-.348 2.231v7.436a7.82 7.82 0 0 0 .348 2.23c.223.607.561 1.158.993 1.618.432.461.948.822 1.516 1.06a5.19 5.19 0 0 0 2.091.372h7.04a6.532 6.532 0 0 0 2.09-.372 4.425 4.425 0 0 0 1.517-1.06c.431-.46.77-1.01.993-1.617.25-.713.369-1.47.348-2.231v-7.436a7.832 7.832 0 0 0-.348-2.23 4.777 4.777 0 0 0-.993-1.618 4.425 4.425 0 0 0-1.516-1.06 5.19 5.19 0 0 0-2.091-.372h-7.11v.075Z"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
