import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";
/*
 * A clock icon with two hands, one indicating the hour and the other the minute.
 * The hands can be rotated by setting the --villa-icon-clock-hours and --villa-icon-clock-minutes CSS custom properties.
 * NOTE:  You have to be clever yourself; 1 for the hour will make it point to 1, and 30 for minutes will point the minute hand to 6.
 *        This is however not a state an actual clock would be in.
 *
 * @cssprop --villa-icon-clock-hours - The hour the hour-hand should point to. (number between 0 and 12)
 * @cssprop --villa-icon-clock-minutes - The minute the minute-hand should point to. (number between 0 and 60)
 * */
@customElement("villa-icon-clock")
export class VillaIconClock extends LitElement {
  render() {
    return html`
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="1"
          fill="none"
        />

        <!-- Hour hand pointing at 12 -->
        <line
          x1="12"
          y1="12"
          x2="12"
          y2="6"
          stroke="currentColor"
          stroke-width="1"
          class="hour-hand"
          style="transform: rotate(var(--hour-angle));transform-origin: 50% 50%;"
        />

        <!-- Minute hand pointing at 12 -->
        <line
          x1="12"
          y1="12"
          x2="12"
          y2="5"
          stroke="currentColor"
          stroke-width="1"
          class="minute-hand"
          style=" transform: rotate(var(--minute-angle)); transform-origin: 50% 50%;"
        />
      </svg>
    `;
  }

  static styles = [
    iconStyles,

    css`
      svg {
        --minute-angle: calc(var(--villa-icon-clock-minutes, 15) * 6deg);
        --hour-angle: calc(var(--villa-icon-clock-hours, 0) * 30deg);
      }
    `,
  ];
}
