import "../villa-icons/villa-icon-close";

import { css, html, LitElement } from "lit";
import {
  customElement,
  property,
  queryAssignedElements,
  state,
} from "lit/decorators.js";

/**
 * @element villa-input
 * @description Form input with clear button.
 *
 * @prop {string} _value - The current value of the input.
 * @prop {boolean | undefined} required - Flag indicating whether the input is required.
 *
 * @cssprop --villa-input-row-gap - Gap between rows in the grid layout.
 *
 * @slot label - Label for input field
 * @slot input - Input field, support text, email at the moment
 * @slot message - Description or additional information for input
 *
 * @event input - Fires when input value is changed
 *
 * @example
 * ```html
 * <villa-input required>
 *   <label slot="label" for="username">Username</label>
 *   <input slot="input" type="text" name="username" aria-describedby="username-message" aria-label="username" />
 *   <span slot="message" id="username-message" aria-live="polite">Enter your username</span>
 * </villa-input>
 * ```
 */

@customElement("villa-input")
export class VillaInput extends LitElement {
  @queryAssignedElements({ slot: "input" }) _input:
    | HTMLInputElement[]
    | undefined;
  @state() _value: string = "";
  @property({ type: Boolean, reflect: true }) required: boolean = false;

  connectedCallback() {
    super.connectedCallback();
    if (this.required) this.querySelector("input")?.toggleAttribute("required");
    this.addEventListener("input", this.#handleInput.bind(this));
  }

  #handleInput() {
    this._value = this.#slottedInput().value;
  }

  #handleButtonClick() {
    this._value = "";
    this.#slottedInput().value = this._value;
    this.#slottedInput().focus();
  }

  #slottedInput() {
    if (!this._input?.[0]) throw Error("no input in slot");
    return this._input[0];
  }

  // TODO optional text color fails a11y contrast check, Willem will come up with new proposal

  static styles = css`
    :host {
      --villa-input-row-gap: 8px;
    }

    div {
      display: grid;
      grid-template-rows: min-content min-content auto;
      grid-template-columns: auto 1fr;
      color: var(--color-text-default, #333);
      font-family: var(--fontstack-body);
    }

    ::slotted([slot="label"]) {
      grid-row: 1;
      grid-column: 1;
      padding-block-end: 0.5rem;
      font: var(--font-utility-lg);
    }

    .Optional {
      grid-row: 1;
      grid-column: 2;
      color: var(--color-text-disabled);
      font: var(--font-utility-lg);
    }

    ::slotted([slot="message"]) {
      grid-row: 3;
      grid-column: 1/-1;
      font: var(--font-utility-md);
    }

    div:has(slot[name="label"]) ::slotted(label),
    div:has(span.Optional) .Optional {
      margin-bottom: var(--villa-input-row-gap);
    }

    div:has(slot[name="message"]) ::slotted([slot="message"]) {
      margin-top: var(--villa-input-row-gap);
    }

    ::slotted(input) {
      --__border-color: var(--color-text-default);

      grid-row: 2;
      grid-column: 1/-1;
      border: 0;
      border-radius: 0;
      padding-inline: 1rem 2.375rem;
      height: 44px;
      color: inherit;
      font: var(--font-utility-xl);
    }

    ::slotted(input:not([disabled])) {
      border: 1px solid var(--__border-color);
    }

    ::slotted(input:hover:not([disabled])),
    ::slotted(input:focus:not([disabled])) {
      outline: 1px solid var(--__border-color);
    }

    ::slotted(input:user-invalid),
    ::slotted(input:user-invalid:focus) {
      --__border-color: var(--color-border-error);
    }

    @supports not selector(input:user-invalid) {
      ::slotted(input:invalid:focus) {
        --__border-color: var(--color-border-error);
      }
    }

    ::slotted(input:disabled) {
      background-color: var(--color-functional-disabled);
      color: var(--color-text-default);
    }

    button {
      --villa-icon-width: 1rem;

      display: flex;
      grid-row: 2;
      grid-column: 2;
      justify-content: center;
      align-items: center;
      justify-self: end;
      cursor: pointer;
      border: none;
      background: transparent;
      padding-inline-end: 1rem;
      height: 100%;
    }
  `;

  render() {
    return html`
      <div>
        <slot name="label"></slot>
        ${this.required
          ? html``
          : html` <span class="Optional">optioneel</span>`}
        <slot name="input"></slot>
        ${this._value
          ? html`<button
              type="button"
              aria-label="Wis veld"
              title="wis veld"
              @click="${this.#handleButtonClick}"
            >
              <villa-icon-close></villa-icon-close>
            </button>`
          : html``}
        <slot name="message" class="Message"></slot>
      </div>
    `;
  }
}
