import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-facebook-color")
export class VillaLogoFacebookColor extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        width="32"
        height="32"
      >
        <path
          fill="#1877F2"
          d="M31 16.002c0-8.288-6.712-15-15-15s-15 6.712-15 15c0 7.487 5.487 13.687 12.65 14.812V20.339H9.837v-4.337h3.813v-3.3c0-3.763 2.237-5.838 5.662-5.838 1.638 0 3.363.287 3.363.287v3.688h-1.887c-1.863 0-2.45 1.162-2.45 2.338v2.812H22.5l-.663 4.337h-3.5v10.488c7.175-1.125 12.65-7.325 12.65-14.813H31Z"
        />
        <path
          fill="#fff"
          d="m21.837 20.339.662-4.337h-4.162v-2.813c0-1.188.575-2.338 2.45-2.338h1.887V7.165s-1.712-.288-3.362-.288c-3.425 0-5.663 2.075-5.663 5.838v3.3H9.837v4.338h3.812v10.487c.763.125 1.55.187 2.35.187s1.575-.062 2.35-.187v-10.5h3.5-.012Z"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
