import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-link")
export class VillaIconLink extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="none"
      >
        <path
          fill="currentColor"
          d="m18.095 13.953 3.041-3.042a5.695 5.695 0 0 0 0-8.047 5.696 5.696 0 0 0-8.047 0l-4.39 4.39a5.695 5.695 0 0 0 0 8.046c.327.328.687.606 1.066.836l2.35-2.35a2.56 2.56 0 0 1-1.221-.681 2.59 2.59 0 0 1 0-3.659l4.39-4.389a2.59 2.59 0 0 1 3.657 0 2.59 2.59 0 0 1 0 3.658l-1.289 1.29a6.98 6.98 0 0 1 .443 3.948Z"
        />
        <path
          fill="currentColor"
          d="m5.905 10.047-3.041 3.041a5.696 5.696 0 0 0 0 8.048 5.696 5.696 0 0 0 8.047 0l4.39-4.39a5.696 5.696 0 0 0-1.065-8.882l-2.35 2.35c.448.107.872.333 1.221.682a2.59 2.59 0 0 1 0 3.658l-4.39 4.39a2.59 2.59 0 0 1-3.657 0 2.59 2.59 0 0 1 0-3.659l1.29-1.29a6.967 6.967 0 0 1-.445-3.948Z"
        />
      </svg>
    `;
  }

  static styles = iconStyles;
}
