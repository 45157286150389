import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-spotify")
export class VillaLogoSpotify extends LitElement {
  @property() variant: "mono" | undefined;

  get #color() {
    switch (this.variant) {
      case "mono":
        return "currentColor";
      default:
        return "#1ED760";
    }
  }

  get #color2() {
    return this.variant === "mono" ? "transparent" : "#FFF";
  }

  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="50"
        height="50"
      >
        <circle cx="247.75" cy="218.2" r="168.991" fill="${this.#color2}" />
        <path
          fill="${this.#color}"
          fill-rule="evenodd"
          d="M346.61 188.376c-60.781-36.095-161.023-39.408-219.057-21.791-9.32 2.828-19.165-2.438-21.98-11.758-2.828-9.32 2.424-19.165 11.758-21.993 66.599-20.229 177.306-16.31 247.286 25.212 8.364 4.983 11.125 15.798 6.155 24.175-4.97 8.364-15.798 11.138-24.162 6.155Zm-1.979 53.468c-4.283 6.909-13.32 9.077-20.229 4.835-50.667-31.138-127.933-40.175-187.893-21.98-7.758 2.357-15.973-2.02-18.33-9.791-2.343-7.771 2.034-15.96 9.805-18.33 68.471-20.768 153.603-10.721 211.798 25.064 6.923 4.242 9.091 13.306 4.849 20.202Zm-23.085 51.34c-3.38 5.562-10.626 7.3-16.161 3.906-44.27-27.057-100-33.172-165.644-18.182-6.316 1.455-12.606-2.505-14.06-8.822-1.441-6.343 2.505-12.633 8.835-14.087 71.825-16.418 133.428-9.347 183.125 21.023 5.549 3.381 7.299 10.627 3.905 16.162Zm-86.464-271.96c-104.135 0-188.553 84.418-188.553 188.553 0 104.148 84.418 188.552 188.553 188.552 104.134 0 188.552-84.404 188.552-188.552 0-104.135-84.418-188.553-188.552-188.553Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
