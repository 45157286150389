import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-npo2")
export class VillaLogoNpo2 extends LitElement {
  render() {
    return html`<svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 44 32"
      width="44"
      height="32"
    >
      <path
        fill="#E5E5E5"
        fill-rule="evenodd"
        d="m17.41 5.861-.872-.87c-.864-.866-1.904-.88-2.874.09l-.69.692c-.972.97-.955 2.009-.091 2.874l.87.87c.865.868 1.906.883 2.876-.088l.69-.692c.972-.971.956-2.01.091-2.876Zm-4.368 4.368-.873-.869c-.864-.865-1.903-.882-2.873.09l-.692.692c-.97.97-.955 2.008-.09 2.874l2.796 2.795c.323.321.686.274.935.026.248-.248.29-.605-.032-.929l-.644-.641c.175-.037.39-.17.644-.423l.738-.74c.972-.97.955-2.008.091-2.875Zm-3.467 5.43-1.852-1.852c-.865-.865-1.903-.882-2.875.09l-.692.69c-.97.97-.955 2.01-.089 2.875l1.85 1.854c.325.322.687.273.936.027.247-.25.29-.61-.033-.93l-1.835-1.837c-.349-.347-.338-.674.053-1.065l.733-.733c.39-.39.718-.402 1.066-.053l1.836 1.835c.32.323.68.28.929.033.248-.25.296-.612-.027-.934Zm18.906 2.335L16.993 29.48c-1.156 1.157-2.483 1.157-3.639 0L1.868 17.994c-1.157-1.157-1.157-2.482 0-3.64L13.354 2.87c1.157-1.157 2.482-1.157 3.639 0L28.48 14.354c1.157 1.157 1.157 2.483.001 3.64ZM15.654 5.908c-.35-.348-.677-.338-1.067.054l-.733.733c-.39.39-.4.718-.052 1.065l.838.84c.348.348.674.338 1.066-.053l.733-.734c.39-.389.4-.718.053-1.065l-.838-.84Zm-3.53 5.209c.348.347.337.675-.054 1.065l-.732.733c-.393.39-.718.402-1.066.052l-.84-.839c-.348-.347-.338-.674.053-1.065l.732-.733c.393-.39.719-.4 1.068-.053l.839.84Z"
        clip-rule="evenodd"
      />
      <path
        fill="#009BDC"
        fill-rule="evenodd"
        d="m12.123 11.117-.839-.84c-.348-.348-.675-.339-1.067.053l-.733.733c-.39.39-.4.718-.052 1.065l.84.84c.349.348.673.338 1.065-.053l.733-.733c.39-.39.401-.718.053-1.065Zm.919-.888c.864.866.88 1.905-.09 2.875l-.739.74c-.253.252-.47.386-.644.423l.643.641c.322.324.281.681.033.93-.248.247-.612.294-.935-.027l-2.797-2.795c-.865-.867-.88-1.905.092-2.875l.69-.691c.97-.972 2.01-.956 2.874-.09l.873.87Zm3.45-3.481-.839-.84c-.349-.348-.676-.338-1.066.054l-.733.733c-.39.39-.401.718-.052 1.065l.838.84c.349.348.675.338 1.066-.053l.734-.734c.388-.39.4-.718.051-1.065Zm.918-.887c.864.866.88 1.905-.09 2.876l-.692.692c-.97.97-2.01.955-2.874.088l-.872-.87c-.864-.865-.88-1.904.091-2.874l.691-.693c.97-.97 2.01-.955 2.874-.089l.872.87Zm-7.834 9.797c.322.323.275.686.025.935-.248.247-.607.29-.929-.034l-1.835-1.834c-.347-.35-.676-.338-1.065.053l-.734.733c-.39.39-.401.718-.054 1.065l1.835 1.837c.324.32.281.68.034.93-.249.246-.611.295-.935-.028l-1.851-1.853c-.866-.865-.881-1.905.09-2.876l.691-.69c.972-.97 2.01-.955 2.875-.089l1.853 1.85Z"
        clip-rule="evenodd"
      />
      <mask
        id="a"
        width="30"
        height="29"
        x="14"
        y="2"
        maskUnits="userSpaceOnUse"
        style="mask-type:luminance"
      >
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M14.966 2h28.347v28.348H14.966V2Z"
          clip-rule="evenodd"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#009BDC"
          fill-rule="evenodd"
          d="M15.833 14.354c-1.156 1.156-1.156 2.482 0 3.639L27.32 29.48c1.157 1.157 2.482 1.157 3.64 0l11.486-11.488c1.156-1.156 1.156-2.482 0-3.638L30.96 2.868c-1.158-1.157-2.483-1.157-3.64 0L15.833 14.354Z"
          clip-rule="evenodd"
        />
      </g>
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M33.903 17.758c1.196 0 1.808-.747 1.808-1.672v-5.304c0-.926-.612-1.673-1.808-1.673H24.33c-1.166 0-1.764.732-1.764 1.629 0 .896.598 1.628 1.764 1.628h7.423c.254 0 .403.134.403.433v1.27c0 .298-.149.432-.403.432h-7.378c-1.195 0-1.809.748-1.809 1.673v5.303c0 .927.614 1.673 1.808 1.672h9.574c1.165 0 1.763-.731 1.763-1.627s-.598-1.628-1.763-1.628h-7.423c-.254 0-.403-.135-.403-.434v-1.269c0-.299.15-.433.403-.433h7.378Z"
        clip-rule="evenodd"
      />
    </svg> `;
  }

  static styles = logoStyles;
}
