import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-arrow-left")
export class VillaIconArrowLeft extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10.56 5.954 5.705 11.25H20.25v1.5H5.705l4.855 5.296-1.106 1.014L2.983 12l6.47-7.06 1.107 1.014Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = iconStyles;
}
