import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-nporadio1")
export class VillaLogoNporadio1 extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 44 32"
        width="44"
        height="32"
      >
        <path
          fill="#E5E5E5"
          fill-rule="evenodd"
          d="m17.29 5.814-.866-.86c-.858-.855-1.89-.87-2.853.09l-.686.682c-.964.959-.948 1.984-.09 2.84l.865.858c.858.858 1.89.872 2.854-.087l.684-.683c.965-.959.95-1.986.091-2.84Zm-4.337 4.314-.866-.858c-.858-.855-1.89-.871-2.852.088l-.687.684c-.964.957-.948 1.983-.09 2.839l2.776 2.76c.32.318.681.271.928.027.246-.245.288-.598-.032-.918l-.638-.634c.173-.036.387-.168.638-.418l.733-.73c.965-.959.948-1.983.09-2.84Zm-3.441 5.363-1.839-1.829c-.859-.855-1.889-.87-2.853.088l-.687.682c-.963.959-.948 1.985-.088 2.84l1.837 1.831c.322.319.68.27.927.027.247-.246.289-.602-.032-.918l-1.822-1.815c-.345-.342-.335-.666.054-1.052l.727-.724c.388-.386.713-.397 1.058-.053l1.822 1.813c.319.32.676.277.922.032.247-.245.294-.603-.026-.922Zm18.766 2.306L16.875 29.143c-1.148 1.143-2.465 1.143-3.612 0L1.861 17.797c-1.148-1.142-1.148-2.452 0-3.594L13.263 2.858c1.149-1.143 2.464-1.143 3.612 0l11.402 11.345c1.149 1.142 1.149 2.451.001 3.594ZM15.546 5.86c-.347-.343-.671-.334-1.059.053l-.728.725c-.387.384-.398.708-.051 1.051l.831.83c.346.344.67.334 1.059-.053l.727-.725c.387-.384.398-.709.053-1.051l-.832-.83Zm-3.504 5.145c.345.343.334.667-.053 1.052l-.729.724c-.388.386-.71.396-1.056.052l-.833-.83c-.346-.342-.336-.665.051-1.051l.728-.725c.389-.385.712-.395 1.059-.052l.833.83Z"
          clip-rule="evenodd"
        />
        <path
          fill="#003576"
          fill-rule="evenodd"
          d="m12.042 11.005-.833-.83c-.346-.343-.67-.334-1.06.052l-.727.725c-.387.385-.397.71-.052 1.052l.833.83c.347.344.669.333 1.058-.052l.728-.725c.387-.384.398-.709.053-1.052Zm.911-.877c.859.856.875 1.882-.09 2.84l-.732.73c-.252.25-.466.382-.64.418l.639.634c.32.32.279.673.032.918-.246.245-.607.291-.928-.026L8.458 12.88c-.858-.856-.873-1.882.09-2.84l.687-.682c.963-.96 1.994-.945 2.852-.089l.866.858Zm3.425-3.438-.833-.83c-.346-.343-.67-.333-1.057.054l-.729.724c-.386.385-.398.708-.051 1.052l.832.828c.346.345.67.335 1.058-.052l.728-.724c.386-.385.397-.71.052-1.052Zm.911-.876c.858.855.875 1.881-.09 2.84l-.686.684c-.963.959-1.995.943-2.853.087l-.865-.86c-.858-.854-.873-1.88.09-2.838l.686-.684c.963-.959 1.996-.944 2.853-.088l.865.859ZM9.513 15.49c.32.32.273.678.025.924-.246.244-.603.287-.922-.033L6.795 14.57c-.345-.345-.672-.334-1.058.053l-.729.724c-.387.385-.398.708-.053 1.051l1.822 1.815c.32.317.278.672.033.919-.247.243-.606.29-.928-.028l-1.837-1.83c-.86-.855-.875-1.882.089-2.84l.686-.682c.965-.96 1.995-.944 2.853-.088l1.84 1.827Z"
          clip-rule="evenodd"
        />
        <mask
          id="a"
          width="29"
          height="28"
          x="14"
          y="2"
          maskUnits="userSpaceOnUse"
          style="mask-type:luminance"
        >
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M14.862 2H43v28H14.862V2Z"
            clip-rule="evenodd"
          />
        </mask>
        <g mask="url(#a)">
          <path
            fill="#003576"
            fill-rule="evenodd"
            d="M15.723 14.203c-1.148 1.142-1.148 2.451 0 3.593l11.402 11.347c1.148 1.143 2.465 1.143 3.613 0L42.14 17.796c1.148-1.142 1.147-2.452 0-3.593L30.738 2.857c-1.148-1.144-2.465-1.144-3.612 0L15.723 14.203Z"
            clip-rule="evenodd"
          />
        </g>
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M30.243 16.016c0-.937-.59-1.4-1.314-1.4-.724 0-1.313.464-1.313 1.4v7.183c0 .936.589 1.399 1.312 1.399.725 0 1.315-.462 1.315-1.4v-7.182ZM21.928 10.486c0-.193.084-.303.271-.354a3.864 3.864 0 0 1 1.488-.116c.162.02.246.11.246.245 0 .026-.007.09-.013.122l-.013.065c-.039.18-.09.257-.245.257-.091 0-.253-.026-.434-.026-.156 0-.382.02-.525.065v2.285c0 .2-.083.283-.284.283h-.207c-.2 0-.284-.084-.284-.283v-2.543ZM26.34 11.908a2.028 2.028 0 0 0-.558-.07c-.51 0-.756.154-.756.489 0 .328.24.476.77.476.188 0 .394-.02.543-.064v-.83Zm-1.773-1.396a.377.377 0 0 1-.026-.135c0-.122.09-.187.181-.226.22-.096.66-.16 1.022-.16.906 0 1.365.392 1.365 1.338v1.603c0 .232-.117.296-.279.335-.22.051-.769.109-1.137.109-.9 0-1.45-.341-1.45-1.056 0-.72.563-1.049 1.397-1.049.33 0 .57.052.7.083v-.095c0-.4-.201-.63-.725-.63-.26 0-.46.057-.647.115a.516.516 0 0 1-.155.032c-.104 0-.162-.058-.22-.2l-.026-.064ZM30.008 10.724a1.625 1.625 0 0 0-.46-.064c-.68 0-.97.411-.97 1.03 0 .617.29 1.03.97 1.03.162 0 .344-.027.46-.065v-1.931Zm0-1.7c0-.199.084-.282.284-.282h.207c.2 0 .284.083.284.283v3.887c0 .212-.103.296-.265.348-.213.071-.653.116-.957.116-1.164 0-1.772-.644-1.772-1.68 0-1.056.634-1.706 1.668-1.706.233 0 .421.032.55.071V9.025ZM31.618 10.338c0-.2.084-.283.285-.283h.206c.201 0 .285.084.285.283v2.69c0 .2-.084.284-.285.284h-.206c-.2 0-.285-.084-.285-.283v-2.691Zm.388-1.66c.285 0 .479.186.479.462 0 .278-.194.464-.479.464-.284 0-.479-.186-.479-.464 0-.276.195-.462.48-.462ZM35.654 11.683c0-.656-.297-1.03-.834-1.03-.537 0-.834.374-.834 1.03 0 .663.297 1.03.834 1.03.537 0 .834-.367.834-1.03Zm-2.458 0c0-1.1.64-1.693 1.624-1.693.983 0 1.623.592 1.623 1.693 0 1.107-.64 1.693-1.623 1.693-.984 0-1.624-.586-1.624-1.693Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
