import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-human")
export class VillaLogoHuman extends LitElement {
  @property() variant: "dark" | "mono" | undefined;

  get #color() {
    return this.variant === "dark" ? "#fff" : "#000";
  }

  get #monoColor() {
    return this.variant === "mono"
      ? "--villa-svg-mono-color: currentColor"
      : "";
  }
  render() {
    return html`<svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 38 32"
      width="38"
      height="32"
      style="${this.#monoColor}"
    >
      <g>
        <path
          fill="var(--villa-svg-mono-color,${this.#color})"
          d="M4.01 14.51H2.514v-4.242H0V21.7h2.515v-5.27H4.01v5.27h2.516V10.268H4.009v4.242ZM10.81 17.234h-.074L9.61 10.268H6.963L9.21 21.7h2.678l2.264-11.432h-2.382l-.961 6.966ZM19.023 15.716l-1.065-5.448H14.6V21.7h2.175v-8.217l1.301 8.217h1.584l1.509-8.217V21.7h2.308V10.268H19.97l-.947 5.448ZM36.03 10.268V15.7l-2.19-5.433h-2.19V21.7h2.057v-6.044l2.426 6.044h1.865V10.268H36.03ZM26.23 10.268 23.968 21.7h2.382l.962-6.967h.073L28.51 21.7h2.648l-2.249-11.432h-2.677Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #FFCB05)"
          d="M37.995 7.364v-.277H12.196l-.038.277h25.837ZM0 7.087v.277h9.137l-.044-.277H0Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #48B9C7)"
          d="M37.998 8.426v-.554H12.086l-.076.554h25.988ZM0 7.872v.554h9.309l-.09-.554H0Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #F37032)"
          d="M0 8.796v.554h9.457l-.09-.554H0ZM38.001 9.35v-.554H11.963l-.076.554H38Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #F5A4C7)"
          d="m12.323 6.164-.037.27v.007H38v-.278H12.323ZM8.988 6.44l-.001-.007-.044-.27H0v.278h8.988Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #ED1C24)"
          d="M37.99 5.491v-.277H12.414l-.038.277h25.616ZM0 5.214v.277h8.835l-.045-.277H0Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #F5A4C7)"
          d="M37.994 25.844v-.277H29.13l.044.277h8.819ZM0 25.567v.277h25.77l.038-.277H0Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #FFCB05)"
          d="M37.993 24.921v-.277H28.98l.046.277h8.967ZM0 24.644v.277h25.898l.038-.277H0Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #48B9C7)"
          d="M0 23.58v.554h26.007l.078-.554H0ZM37.995 24.134v-.554h-9.186l.089.554h9.097Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #F37032)"
          d="M0 22.657v.554h26.136l.077-.554H0ZM37.99 23.211v-.554h-9.332l.09.554h9.242Z"
        />
        <path
          fill="var(--villa-svg-mono-color, #ED1C24)"
          d="M0 26.509v.277h25.638l.039-.277H0ZM37.997 26.786v-.277h-8.718l.045.277h8.673Z"
        />
      </g>
    </svg>`;
  }

  static styles = logoStyles;
}
