import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-check")
export class VillaIconCheck extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M22.743 6.67 10.277 20.493l-8.02-8.892 1.486-1.34 6.534 7.246L21.257 5.33l1.486 1.34Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = iconStyles;
}
