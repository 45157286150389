import { css, html, LitElement, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";

type ButtonSizeVariants = "sm" | "";
enum ButtonUIVariants {
  DEFAULT = "",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}

@customElement("villa-button")
export class VillaButton extends LitElement {
  @property({ type: Boolean, reflect: true }) disabled: boolean | undefined;
  @property({ type: String, reflect: true }) size:
    | ButtonSizeVariants
    | undefined;
  @property({ type: String, reflect: true }) variant:
    | ButtonUIVariants
    | undefined;
  @property({ attribute: "data-aria-label", type: String }) ariaLabel:
    | string
    | null;
  @property({ attribute: "data-aria-controls", type: String }) ariaControls:
    | string
    | undefined;
  @property({ attribute: "data-aria-expanded", type: String }) ariaExpanded:
    | string
    | null;

  @property() onClick?: () => void;

  constructor() {
    super();
    this.ariaExpanded = null;
    this.ariaLabel = null;
  }

  private _handleButtonClick = (e: Event) => {
    if (this.disabled) e.preventDefault();
  };

  render() {
    return html`
      <button
        class="VillaButton"
        aria-controls="${this.ariaControls || nothing}"
        aria-disabled="${this.disabled || nothing}"
        aria-expanded="${this.ariaExpanded || nothing}"
        aria-label="${this.ariaLabel || "Button"}"
        @click="${this._handleButtonClick}"
      >
        <span class="VillaButton-label">
          <slot></slot>
        </span>
      </button>
    `;
  }

  static styles = [
    css`
      /* stylelint-disable no-descending-specificity */

      .VillaButton {
        --villa-icon-width: 1.5rem;

        display: flex;
        justify-content: center;
        align-items: center;
        appearance: none;
        transition:
          color var(--motion-fast) var(--motion-easein),
          border-color var(--motion-fast) var(--motion-easein),
          background-color var(--motion-fast) var(--motion-easein);
        cursor: pointer;
        box-sizing: border-box;
        margin: 0;
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-primary-interactive-default);
        background-color: var(--color-primary-interactive-default);
        padding: var(--villa-button-padding, 0.688rem 0.75rem); /* 11px 12px */
        min-height: 2.75rem;
        color: var(--color-primary-oninteractive-default);
        text-align: center;
        text-decoration: none;
      }

      .VillaButton-label {
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
        width: max-content;
        font: var(--font-utility-xl);
        user-select: none;
      }

      .VillaButton:is(:hover, :focus) {
        transition:
          color var(--motion-fast) var(--motion-easeout),
          border-color var(--motion-fast) var(--motion-easeout),
          background-color var(--motion-fast) var(--motion-easeout);
        cursor: pointer;
      }

      .VillaButton:hover {
        border-color: var(--color-primary-interactive-hover);
        background-color: var(--color-primary-interactive-hover);
        color: var(--color-primary-oninteractive-hover);
      }

      .VillaButton:active {
        border-color: var(--color-primary-interactive-active);
        background: var(--color-primary-interactive-active);
        color: var(--color-primary-oninteractive-active);
      }

      .VillaButton:focus-visible {
        outline: var(--outline-width) solid var(--outline-color);
        outline-offset: var(--outline-offset);
      }

      /* Secondary */
      :host([variant="secondary"]) .VillaButton {
        border-color: var(--color-secondary-border-default);
        background: var(--color-transparent-0);
        color: var(--color-secondary-oninteractive-default);
      }

      :host([variant="secondary"]) .VillaButton:is(:hover) {
        border-color: var(--color-secondary-border-hover);
        background: var(--color-secondary-interactive-hover);
        color: var(--color-secondary-oninteractive-hover);
      }

      :host([variant="secondary"]) .VillaButton:active {
        border-color: var(--color-secondary-border-active);
        background: var(--color-secondary-interactive-active);
        color: var(--color-secondary-oninteractive-active);
      }

      /* Tertiary */
      :host([variant="tertiary"]) .VillaButton {
        border-color: var(--color-tertiary-border-default);
        background: var(--color-transparent-0);
        padding: 0.5rem 0.688rem; /* 10px 11px */
        color: var(--color-tertiary-oninteractive-default);
      }

      :host([variant="tertiary"]) .VillaButton:is(:hover) {
        border-color: var(--color-tertiary-border-hover);
        background: var(--color-tertiary-interactive-hover);
        color: var(--color-tertiary-oninteractive-hover);
      }

      :host([variant="tertiary"]) .VillaButton:active {
        border-color: var(--color-tertiary-border-active);
        background: var(--color-tertiary-interactive-active);
        color: var(--color-tertiary-oninteractive-active);
      }

      /* Small */
      :host([size="sm"]) .VillaButton,
      :host([size="sm"][variant="tertiary"]) .VillaButton {
        padding: var(--villa-button-padding, 0.438rem 0.625rem); /* 7px 10px */
        min-height: 2rem;
      }

      :host([size="sm"]) .VillaButton-label {
        --villa-icon-width: 1rem;

        font-weight: var(--utility-lg-fontweight);
        font-size: calc(var(--utility-lg-fontsize-max) * 1rem);
      }

      /* Disabled */
      :host .VillaButton[aria-disabled],
      :host([variant="secondary"]) .VillaButton[aria-disabled] {
        cursor: default;
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-functional-disabled);
        background-color: var(--color-transparent-0);
        padding: 0.625rem 0.688rem; /* 10px 11px */
        pointer-events: none;
        color: var(--color-functional-disabled);
      }

      :host([variant="tertiary"]) .VillaButton[aria-disabled] {
        background: var(--color-transparent-0);
        color: var(--color-text-disabled);
      }

      /* Transparent */
      :host([transparent]) .VillaButton,
      :host([transparent]) .VillaButton:active {
        border-color: transparent;
        background: transparent;
        color: inherit;
      }
    `,
  ];
}
