import { css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";

import { VillaButton } from "./villa-button";

// Additions:
// Add support for: rel
// Add support for anaytics (data-attributes)

@customElement("villa-button-link")
export class VillaButtonLink extends VillaButton {
  @property({ type: String }) href: string | undefined;
  @property({ type: String }) target: string | undefined;
  @property({ attribute: "data-aria-label", type: String }) ariaLabel:
    | string
    | null;

  constructor() {
    super();
    this.ariaLabel = null;
  }

  render() {
    return html`
      <a
        class="VillaButton"
        aria-disabled="${ifDefined(this.disabled)}"
        href="${ifDefined(this.href && !this.disabled ? this.href : undefined)}"
        aria-label="${ifDefined(
          this.ariaLabel && !this.disabled ? this.ariaLabel : undefined,
        )}"
        target="${this.target || nothing}"
      >
        <span class="VillaButton-label">
          <slot></slot>
        </span>
      </a>
    `;
  }

  static styles = [
    ...VillaButton.styles,
    css`
      .VillaButton {
        text-decoration: none;
      }
    `,
  ];
}
