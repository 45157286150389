import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-youtube")
export class VillaLogoYoutube extends LitElement {
  @property() variant: "mono" | undefined;

  get #color() {
    switch (this.variant) {
      case "mono":
        return "currentColor";
      default:
        return "#FF0000";
    }
  }

  get #color2() {
    return this.variant === "mono" ? "transparent" : "#FFF";
  }

  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width="32"
        height="32"
        fill="none"
      >
        <path
          fill="${this.#color}"
          fill-rule="evenodd"
          d="M28.5026 5.667C26.0083 5 16.0002 5 16.0002 5S5.997 5 3.4974 5.667C2.1227 6.0335 1.0387 7.118.667 8.4974 0 10.9917 0 16.1997 0 16.1997s0 5.2079.667 7.7023c.3665 1.3746 1.451 2.4586 2.8304 2.8303 2.4996.667 12.5028.667 12.5028.667s10.0085 0 12.5028-.667c1.3747-.3665 2.4587-1.4509 2.8304-2.8303.667-2.4944.667-7.7023.667-7.7023s0-5.208-.667-7.7023c-.3665-1.3747-1.4513-2.4587-2.8308-2.8304ZM12.803 21.0002v-9.6011l8.3132 4.8006-8.3132 4.8005Z"
          clip-rule="evenodd"
        />
        <path
          fill="${this.#color2}"
          fill-rule="evenodd"
          d="m12.8037 20.9999 8.313-4.8005-8.313-4.8005v9.601Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
