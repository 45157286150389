import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-play")
export class VillaIconPlay extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          fill="currentColor"
          d="M19.125 12.29 4.5 19.959V4.62l14.625 7.67Z"
        />
      </svg>
    `;
  }

  static styles = iconStyles;
}
