import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-arrow-down")
export class VillaIconArrowDown extends LitElement {
  render() {
    return html`<svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 3.5v14.546l5.295-4.855 1.014 1.106-7.06 6.47-7.06-6.47 1.014-1.106 5.296 4.855V3.5h1.5Z"
      />
    </svg>`;
  }

  static styles = iconStyles;
}
