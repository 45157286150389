import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-linkedin-color")
export class VillaLogoLinkedinColor extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        width="32"
        height="32"
      >
        <g>
          <path
            fill="#0288D1"
            d="M29.5 25.75a3.75 3.75 0 0 1-3.75 3.75H6.25a3.75 3.75 0 0 1-3.75-3.75V6.25A3.75 3.75 0 0 1 6.25 2.5h19.5a3.75 3.75 0 0 1 3.75 3.75v19.5Z"
          />
          <path
            fill="#fff"
            d="M7 12.25h3.75V25H7V12.25Zm1.864-1.5h-.021C7.723 10.75 7 9.916 7 8.874 7 7.81 7.746 7 8.886 7s1.843.81 1.864 1.874c0 1.041-.724 1.876-1.886 1.876ZM25 25h-3.75v-6.824c0-1.649-.919-2.774-2.394-2.774-1.126 0-1.735.76-2.03 1.493-.108.262-.076.988-.076 1.355V25H13V12.25h3.75v1.962c.54-.837 1.387-1.962 3.553-1.962 2.684 0 4.696 1.688 4.696 5.456L25 25Z"
          />
        </g>
      </svg>
    `;
  }

  static styles = logoStyles;
}
