import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { logoStyles } from "../villa-logo-styles";

@customElement("villa-logo-npo1")
export class VillaLogoNpo1 extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 44 32"
        width="44"
        height="32"
      >
        <path
          fill="#E5E5E5"
          fill-rule="evenodd"
          d="m17.417 5.86-.873-.869c-.864-.866-1.905-.881-2.875.09l-.691.692c-.972.97-.955 2.009-.09 2.874l.87.87c.866.868 1.906.883 2.877-.088l.69-.692c.972-.971.957-2.01.092-2.876Zm-4.37 4.37-.873-.87c-.865-.865-1.904-.882-2.875.09l-.692.692c-.971.97-.955 2.008-.09 2.874l2.797 2.796c.323.321.687.274.935.026.248-.248.29-.605-.032-.929l-.644-.642c.175-.037.39-.17.644-.423l.739-.74c.972-.97.955-2.007.09-2.875Zm-3.468 5.43-1.853-1.852c-.866-.866-1.904-.882-2.876.089l-.692.69c-.97.971-.956 2.01-.09 2.876l1.852 1.854c.324.322.686.273.935.027.248-.25.29-.61-.033-.93l-1.836-1.837c-.348-.347-.337-.675.054-1.065l.733-.733c.39-.391.719-.403 1.066-.054l1.837 1.836c.32.323.68.28.929.032.249-.248.296-.61-.026-.934Zm18.913 2.334L16.999 29.482c-1.157 1.157-2.484 1.157-3.64 0L1.87 17.994c-1.158-1.156-1.158-2.482 0-3.64l11.49-11.486c1.158-1.157 2.483-1.157 3.64 0L28.49 14.355c1.157 1.156 1.157 2.482 0 3.64ZM15.659 5.908c-.349-.348-.676-.338-1.067.053l-.733.734c-.39.39-.401.718-.052 1.065l.838.84c.349.349.675.338 1.067-.053l.733-.734c.39-.389.4-.718.053-1.065l-.839-.84Zm-3.53 5.21c.347.347.336.675-.054 1.064l-.733.734c-.393.39-.718.401-1.067.052l-.839-.84c-.348-.346-.339-.674.052-1.064l.733-.734c.392-.39.719-.4 1.068-.053l.84.84Z"
          clip-rule="evenodd"
        />
        <path
          fill="#D8151B"
          fill-rule="evenodd"
          d="m12.265 11.155-.844-.832c-.35-.344-.68-.335-1.073.052l-.738.727c-.392.386-.402.711-.052 1.055l.844.832c.351.345.678.334 1.072-.053l.737-.726c.393-.385.404-.71.054-1.055Zm.924-.88c.87.859.886 1.887-.091 2.848l-.742.733c-.255.25-.473.382-.648.419l.647.635c.324.32.282.675.032.92-.25.246-.615.292-.94-.026l-2.814-2.768c-.87-.858-.884-1.887.092-2.848l.695-.684c.976-.962 2.022-.947 2.89-.089l.879.86Zm3.47-3.447-.843-.832c-.351-.344-.68-.334-1.072.054l-.738.726c-.392.386-.404.71-.052 1.054l.843.832c.35.345.679.335 1.072-.053l.738-.726c.391-.386.403-.712.052-1.055Zm.924-.878c.87.857.887 1.886-.091 2.848l-.695.685c-.976.962-2.022.946-2.891.087l-.878-.861c-.869-.857-.885-1.886.092-2.847l.695-.686c.976-.96 2.023-.946 2.892-.088l.876.862Zm-7.88 9.702c.323.321.276.68.025.926-.25.246-.61.288-.935-.032l-1.845-1.818c-.35-.345-.68-.334-1.073.053l-.738.726c-.392.387-.403.71-.053 1.055l1.845 1.82c.326.317.283.673.034.92-.25.244-.614.292-.94-.027L4.161 17.44c-.871-.858-.887-1.887.09-2.849l.696-.683c.977-.962 2.021-.946 2.891-.088l1.864 1.832Z"
          clip-rule="evenodd"
        />
        <mask
          id="a"
          width="29"
          height="29"
          x="15"
          y="2"
          maskUnits="userSpaceOnUse"
          style="mask-type:luminance"
        >
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M15.075 2.124h28.489V30.35H15.075V2.124Z"
            clip-rule="evenodd"
          />
        </mask>
        <g mask="url(#a)">
          <path
            fill="#D8151B"
            fill-rule="evenodd"
            d="M15.947 14.425c-1.162 1.151-1.162 2.471 0 3.623L27.49 29.486c1.162 1.152 2.495 1.152 3.658 0l11.544-11.438c1.162-1.152 1.16-2.473 0-3.623L31.149 2.988c-1.163-1.153-2.496-1.153-3.658 0L15.947 14.425Z"
            clip-rule="evenodd"
          />
        </g>
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M31.154 11.012c0-1.346-.887-2.012-1.975-2.012s-1.974.666-1.974 2.012v10.325c0 1.346.886 2.013 1.974 2.013s1.975-.667 1.975-2.013V11.012Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = logoStyles;
}
