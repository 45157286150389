import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-arrow-right")
export class VillaIconArrowRight extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height="24"
        width="24"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M14.046 4.94 20.517 12l-6.47 7.06-1.107-1.014 4.855-5.296H3.25v-1.5h14.545L12.94 5.954l1.106-1.014Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = iconStyles;
}
