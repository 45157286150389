import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-close")
export class VillaIconClose extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M11.03 12.445 5 6.415 6.414 5l6.03 6.03L18.476 5l1.415 1.414-6.031 6.03 6.03 6.031-1.414 1.415-6.03-6.031-6.03 6.03L5 18.476l6.03-6.03Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }

  static styles = iconStyles;
}
