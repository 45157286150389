import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { iconStyles } from "./villa-icon-styles";

@customElement("villa-icon-audio")
export class VillaIconAudio extends LitElement {
  render() {
    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          fill="currentColor"
          d="M16.42 1.652 18.097 0a13.64 13.64 0 0 1 4.378 5.27A15.638 15.638 0 0 1 24 12c0 2.326-.52 4.624-1.524 6.73A13.64 13.64 0 0 1 18.098 24l-1.679-1.652a11.383 11.383 0 0 0 3.904-4.5A13.27 13.27 0 0 0 21.683 12a13.27 13.27 0 0 0-1.36-5.848 11.383 11.383 0 0 0-3.904-4.5ZM4.12 8.959l6.18-6.08v18.243L4.12 15.04H0V8.96h4.12Zm8.457-3.527 1.669-1.631a9.169 9.169 0 0 1 3.09 3.466A10.06 10.06 0 0 1 18.407 12a10.261 10.261 0 0 1-1.102 4.743 9.272 9.272 0 0 1-3.09 3.466l-1.669-1.641a7.227 7.227 0 0 0 2.586-2.707A7.845 7.845 0 0 0 16.11 12a7.844 7.844 0 0 0-.947-3.861 7.144 7.144 0 0 0-2.586-2.696v-.01Z"
        />
      </svg>
    `;
  }

  static styles = iconStyles;
}
