import { css } from "lit";

export const iconStyles = css`
  :host {
    display: inline-block;
    width: var(--villa-icon-width, 1em);
    height: auto;
    line-height: 0;
  }

  svg {
    fill: currentcolor;
    width: inherit;
    height: inherit;
  }
`;
